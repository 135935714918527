		
<template>
<div class="outer-wrapper">
    <div class="auth">

		<div class="inner-wrapper">

			<div class="heading-section">
				<h1 class="heading3">What's your email address?</h1>
			</div>	

			<form @submit.prevent="handleSubmit">
				<div class="form-group">
					<label for="email">Email address</label>
					<input type="text" v-model="newUser.email" name="email" class="form-control" :class="{ 'is-invalid': submitted && errors.has('email') }" />
					<div v-if="submitted && errors.has('email')" class="invalid-feedback">{{ errors.get('email')[0] }}</div>
					<div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
				</div>
				<Loader :loading="isLoading" />
				<div v-if="!isLoading" class="form-group btn-container">
					<button class="button btn-primary" >Continue</button>
				</div>
			</form>
   		</div>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router';
import account from '@/helpers/account.js';
import Loader from '@/components/Loader'

export default {
    data () {
        return {
			submitted: true,
			errors: new Map(),
			isLoading: false,
        }
    },
	components: {
		Loader,
	},
    computed: {
		...mapState('account', ['status', 'newUser']),
		...mapState({
				alert: state => state.alert,
			}),
    },
    methods: {
        ...mapActions('account', ['register', 'checkEmail']),
		...mapActions({
			error: "alert/error",
		}),
        handleSubmit(e) {
			if(this.checkForm()) {
				this.isLoading = true;
				this.checkEmail({ email: this.newUser.email })
					.then(response => {
						router.push({ name: "Register Password", query: { redirect: this.$route.query.redirect }})
					},
					error => {
						this.isLoading = false;
						this.error(error);
					});
			}
		},
		checkForm() {
			this.errors = new Map();
			let emailErrors = [];
			if(!this.newUser.email || this.newUser.email == null) {
				emailErrors.push('Email is required');
			}
			if(!account.validEmail(this.newUser.email)) {
				emailErrors.push('Must be a valid email');
			}
			if(emailErrors.length > 0) {
				this.errors.set('email', emailErrors);
			}

			if (!this.errors.size > 0) {
				return true;
			}
			return false;
			e.preventDefault();
		},
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: { name: 'Register', query: { redirect: this.$route.query.redirect }},
			},
			right: {
				type: 'exit',
				route: { name: 'Home' },
			},
		});

		if(this.newUser.firstName == null || this.newUser.lastName == null) {
			router.push({ name: "Register", query: { redirect: this.$route.query.redirect }})
		}
	}
};
</script>	

		